<template>
  <div :class="$style.orders">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="query.search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectOrder"
          @input="query.search = $event"
        />
        <el-button
          :class="$style.button"
          style="margin-left: 0; margin-bottom: 0; border-radius: 0"
          type="primary"
          @click="getList"
        >
          Показать
        </el-button>
      </div>
      <el-button
        size="big"
        type="primary"
        :class="$style.button"
        @click="$router.push($options.ADDEVENT_ADMIN_ROUTES.ORDERS.CREATE)"
        >Создать заказ</el-button
      >
    </div>
    <el-table :data="orders" stripe>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column prop="startAt" label="Дата cоздания" sortable>
        <template slot-scope="scope">
          {{ formatDate(scope.row?.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="deliveryType" label="Тип получения" sortable>
        <template slot-scope="scope">
          {{ $options.DELIVERY_TYPE_DISPLAY[scope.row?.deliveryType] }}
        </template>
      </el-table-column>
      <el-table-column prop="deliveryTypeFrom" label="Тип возврата" sortable>
        <template slot-scope="scope">
          {{ $options.DELIVERY_TYPE_DISPLAY[scope.row?.deliveryTypeFrom] }}
        </template>
      </el-table-column>
      <el-table-column prop="startAt" label="Дата начала аренды" sortable>
        <template slot-scope="scope">
          {{ formatDate(scope.row?.startAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="finishAt" label="Дата окончания аренды" sortable>
        <template slot-scope="scope">
          {{ formatDate(scope.row?.finishAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Статус" sortable>
        <template slot-scope="scope">
          {{ $options.ADDEVENT_ORDER_STATUSES_DISPLAY[scope.row?.status] }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="Цена" sortable />
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            :view-link="scope.row?.msUrl"
            :edit-link="
              getRoute({
                route: $options.ADDEVENT_ADMIN_ROUTES.ORDERS.UPDATE,
                params: { id: scope.row.id },
              })
            "
            @delete="removeOrder(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      layout="prev, pager, next"
      background
      :page-size="query.limit"
      :total="total"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getList"
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'

import notifications from '@/mixins/notifications'

import { formatDate } from '@/helpers/index.js'

import { DELIVERY_TYPE_DISPLAY } from '@/constants/deliveryType'
import { getRoute, ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { SORTING_TYPES } from '@/constants/common'
import { ADDEVENT_ORDER_STATUSES_DISPLAY } from '@/constants/orderStatuses'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'

export default {
  DELIVERY_TYPE_DISPLAY,
  ADDEVENT_ADMIN_ROUTES,
  SORTING_TYPES,
  ADDEVENT_ORDER_STATUSES_DISPLAY,
  components: { ActionButtons, Autocomplete },
  mixins: [notifications],
  data() {
    return {
      orders: [],
      total: 0,
      query: {
        page: this.$route.query.page ?? 1,
        limit: 20,
        orderBy: this.$route.query.orderBy ?? 'created_at',
        orderDir: this.$route.query.orderDir ?? SORTING_TYPES.DESC,
        search: this.$route.query.search ?? '',
      },
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.query.limit)
    },
  },
  async created() {
    await this.getList()
    this.$router.push({query: this.query})
  },
  async updated() {
    if (JSON.stringify(this.$router.query) !== JSON.stringify(this.query))
      this.$router.push({query: this.query})
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.OrdersActions.getList({
          ...this.query,
        })

      loading.close()

      if (error) return

      this.orders = value.data ?? []
      this.total = value?.meta?.count ?? 0
    },
    async removeOrder(order) {
      const isConfirm = confirm(`Вы точно хотите удалить заказ: ${order.name}`)

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.OrdersActions.delete(
        order?.id,
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка удаления заказа', 'error')
        return
      }

      this.showNotification('Заказ успешно удален', 'success')

      await this.getList()
    },
    formatDate(date) {
      return formatDate(date)
    },
    handleSelectOrder(order) {
      this.$router.push(
        this.getRoute({
          route: ADDEVENT_ADMIN_ROUTES.ORDERS.UPDATE,
          params: { id: order?.id },
        }),
      )
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.AddeventServiceCore.OrdersActions.getList({
          ...this.query,
          search: queryString,
          limit: 100,
        })

      if (error) return

      setSearchItems(value.data)
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.orders {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
